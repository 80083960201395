import React, { useEffect, useRef, useState } from "react";
import styles from "./App.module.scss";
import { motion, useAnimation } from "framer-motion";
import Lottie from "lottie-react";
import cog from "./cog.json";

const delays = { subtitle: 1.4, buttons: 0, buttonShadow: 0.5 };
const buttonColours = {
  left: "#bb29bb",
  right: "#409",
  top: "#10069f",
  bottom: "#0085ca",
};
const BUTTON_CONTAINER_SIZE = 100;
const DISTANCE_FROM_CENTER_BOX = 120;

const BUTTON_SHADOW_BLUR = "250px";
const BUTTON_SHADOW_RADIUS = "30px";

// const BUTTON_SHADOW_BLUR = "150px";
// const BUTTON_SHADOW_RADIUS = "5px";

const BUTTON_NAMES = {
  left: "skills",
  right: "projects",
  top: "about me",
  bottom: "cv",
};

const BUTTON_LOTTIES = {
  left: cog,
  right: cog,
  top: cog,
  bottom: cog,
};

const App = () => {
  const centerBoxRef = useRef<HTMLDivElement>(null);

  const [hasLayedOut, setHasLayedOut] = useState(false);

  /**
   * Distance between centres of opposing buttons (i.e. left/right or top/bottom)
   */
  const [opposingButtonsSeparation, setOpposingButtonsSeparation] = useState({
    x: 0,
    y: 0,
  });

  const initialButtonLocations = {
    left: {
      x: opposingButtonsSeparation.x / 2,
      y: opposingButtonsSeparation.y / 2,
    },
    right: {
      x: -opposingButtonsSeparation.x / 2,
      y: -opposingButtonsSeparation.y / 2,
    },
    top: {
      x: -opposingButtonsSeparation.x / 2,
      y: opposingButtonsSeparation.y / 2,
    },
    bottom: {
      x: opposingButtonsSeparation.x / 2,
      y: -opposingButtonsSeparation.y / 2,
    },
  };

  const buttonColours = ["tomato", "darksalmon", "cyan", "lightgreen"];
  const [buttonCoords, setButtonCoords] = useState({
    left: { x: 0, y: 0 },
    right: { x: 0, y: 0 },
    top: { x: 0, y: 0 },
    bottom: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (!centerBoxRef.current) return;
    const { left, right, top, bottom } =
      centerBoxRef.current?.getBoundingClientRect();
    const middleX = left + (right - left) / 2;
    const middleY = top + (bottom - top) / 2;

    setButtonCoords({
      left: {
        x: left - (BUTTON_CONTAINER_SIZE + DISTANCE_FROM_CENTER_BOX),
        y: middleY - BUTTON_CONTAINER_SIZE / 2,
      },
      right: {
        x: right + DISTANCE_FROM_CENTER_BOX,
        y: middleY - BUTTON_CONTAINER_SIZE / 2,
      },
      top: {
        x: middleX - BUTTON_CONTAINER_SIZE / 2,
        y: top - (BUTTON_CONTAINER_SIZE + DISTANCE_FROM_CENTER_BOX),
      },
      bottom: {
        x: middleX - BUTTON_CONTAINER_SIZE / 2,
        y: bottom + DISTANCE_FROM_CENTER_BOX,
      },
    });

    setOpposingButtonsSeparation({
      x: right - left + 2 * DISTANCE_FROM_CENTER_BOX + BUTTON_CONTAINER_SIZE,
      y: bottom - top + 2 * DISTANCE_FROM_CENTER_BOX + BUTTON_CONTAINER_SIZE,
    });

    setHasLayedOut(true);
  }, [centerBoxRef]);

  return (
    <div className={styles.large_container}>
      <div ref={centerBoxRef}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.7 }}
          className={styles.title}
        >
          Tomasz Stevens
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: delays.subtitle, duration: 0.5 }}
          className={styles.subtitle}
        >
          Software Engineer and Tech Enthusiast
        </motion.div>
        {hasLayedOut &&
          Object.keys(buttonCoords).map((direction, i) => (
            <>
              <ButtonShadow
                size={BUTTON_CONTAINER_SIZE}
                // @ts-ignore
                coords={buttonCoords[direction]}
                // @ts-ignore
                direction={direction}
              />
              <ButtonContainer
                size={BUTTON_CONTAINER_SIZE}
                colour={buttonColours[i]}
                // @ts-ignore
                coords={buttonCoords[direction]}
                // @ts-ignore
                direction={direction}
                // @ts-ignore
                initialLocation={initialButtonLocations[direction]}
                index={i}
              />
            </>
          ))}
      </div>
    </div>
  );
};

interface ButtonContainerProps {
  size?: number;
  colour?: string;
  coords: { x: number; y: number };
  direction: "left" | "right" | "top" | "bottom";
  initialLocation?: { x: number; y: number };
  index?: number;
  title: string;
}

const INITIAL_OFFSET = 100;

// const initialLocations = {
//   left: { x: -INITIAL_OFFSET, y: 0 },
//   right: { x: INITIAL_OFFSET, y: 0 },
//   top: { x: 0, y: -INITIAL_OFFSET },
//   bottom: { x: 0, y: INITIAL_OFFSET },
// };

const DELAY = 0.05;
// const customDelays = {
//   left: DELAY * 2,
//   right: DELAY * 3,
//   bottom: 0,
//   top: DELAY * 2,
// };
const customDelays = {
  left: 0,
  right: DELAY * 2,
  bottom: DELAY * 3,
  top: DELAY,
};

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  size,
  colour,
  coords,
  direction,
  initialLocation,
  index,
  title,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      controls.start({
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
          delay: delays.subtitle + delays.buttons + customDelays[direction],
          duration: 0.6,
        },
      });
      // return controls.start({
      //   // boxShadow: `0px 0px 80px 5px ${buttonColours[direction]}`,
      //   // border: "none",
      //   transition: {
      //     delay: 0.8 + delays.subtitle + delays.buttons,
      //     duration: 0.85,
      //   },
      // });
    };
    sequence();
  }, [controls, direction]);

  return (
    <motion.div
      initial={{
        ...initialLocation,
        opacity: 0,
        // boxShadow: `0px 0px 20px 5px ${buttonColours[direction]}`,
        // border: `1px solid ${buttonColours[direction]}`,
      }}
      animate={controls}
      // transition={{
      //   delay: delays.subtitle + delays.buttons + customDelays[direction],
      //   duration: 0.6,
      // }}
      // transition={{ delay: 2 + index * 0.6 }}
      whileHover={{ scale: 1.1 }}
      style={{
        height: size,
        width: size,
        backgroundColor: "#f2f2f2",
        border: `1px solid ${buttonColours[direction]}`,
        position: "absolute",
        top: coords.y,
        left: coords.x,
        borderRadius: "50%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        color: "#aaa",
        flexDirection: "column",
      }}
    >
      {/* <Lottie
        style={{ padding: 15 }}
        animationData={BUTTON_LOTTIES[direction]}
      /> */}
      {BUTTON_NAMES[direction].split(" ").map((word) => (
        <span key={word}>{word}</span>
      ))}
    </motion.div>
  );
};

const ButtonShadow: React.FC<ButtonContainerProps> = ({
  size,
  coords,
  direction,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      await controls.start({
        opacity: 1,
        transition: {
          delay:
            delays.subtitle +
            delays.buttons +
            delays.buttonShadow +
            customDelays[direction] * 3,
          duration: 0.6,
        },
      });
    };
    sequence();
  }, [controls, direction]);

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={controls}
      // whileHover={{ scale: 1.3 }}
      // transition={{ delay: 2 + index * 0.6 }}
      style={{
        height: size,
        width: size,
        backgroundColor: "#f2f2f2",
        boxShadow: `0px 0px ${BUTTON_SHADOW_BLUR} ${BUTTON_SHADOW_RADIUS} ${buttonColours[direction]}`,
        // border: `3px solid ${buttonColours[direction]}`,
        position: "absolute",
        top: coords.y + 1,
        left: coords.x + 1,
        borderRadius: "50%",
        cursor: "pointer",
      }}
    />
  );
};

export default App;
